
export class UsuarioAdminRequest{
    idUsuarioAdmin :number|null;
    email: string;  
    nome: string;  
    telefone: string;  
    cpf: string;  
    ativo: boolean;    
    listaIdPerfil: number[];    
    master: boolean;
}