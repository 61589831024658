import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { UsuarioAdminLoginResponse } from '../../responses/usuario-admin/usuario-admin-login.response';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient, 
    @Inject(DOCUMENT) private _doc: Document) {
    super();
  }

  public signInWithGoogle(token: string, ip: string): Observable<any> {

    let userAuthRequest ={
      token: token,
      ip: ip
    };

    return this.httpClient.post<any>(environment.urlApiUsuarioAdmin + "Auth", userAuthRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  updateToken(userLoginResponse: UsuarioAdminLoginResponse) {
    
    this._doc.defaultView?.sessionStorage?.setItem("token", JSON.stringify(userLoginResponse));
  }

  getToken() {
    let userLoginResponse: UsuarioAdminLoginResponse = JSON.parse(window?.sessionStorage?.getItem("token")!);
    return userLoginResponse.token; 
  }

  getUserInfos():UsuarioAdminLoginResponse {
    let userLoginResponse: UsuarioAdminLoginResponse = JSON.parse(window?.sessionStorage?.getItem("token")!);
    return userLoginResponse;
  }

  logout() {
    this._doc.defaultView?.sessionStorage?.removeItem("token");
    this.router.navigate(['/'])
  }
  
  isAuthenticated(): boolean {
    return this._doc.defaultView?.sessionStorage?.getItem("token") == null ? false : true;
  }
 
}
 