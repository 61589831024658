import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { ListaPerfilResponse } from '../../responses/usuario-admin/lista-perfil.response';
import { PerfilRequest } from '../../requests/usuario-admin/perfil.request';
import { PerfilResponse } from '../../responses/usuario-admin/perfil.response';

@Injectable({
  providedIn: 'root'
})
export class PerfilService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(pesquisa:string, paginaAtual:number): Observable<ListaPerfilResponse> {
    let uri:string = `Perfil?pesquisa=${pesquisa}&paginaAtual=${paginaAtual}`;
    
    return this.httpClient.get<ListaPerfilResponse>(environment.urlApiUsuarioAdmin + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public excluir(idPerfil:number): Observable<ReturnStruct> {    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiUsuarioAdmin + `Perfil/idPerfil/${idPerfil}`,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Incluir(perfilRequest:PerfilRequest): Observable<ReturnStruct> {    
    return this.httpClient.post<ReturnStruct>(environment.urlApiUsuarioAdmin + `Perfil`, perfilRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Alterar(idPerfil:number, perfilRequest:PerfilRequest): Observable<ReturnStruct> {    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuarioAdmin + `Perfil/idPerfil/${idPerfil}`, perfilRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public ObterPorId(idPerfil:number): Observable<PerfilResponse> {    
    return this.httpClient.get<PerfilResponse>(environment.urlApiUsuarioAdmin + `Perfil/idPerfil/${idPerfil}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  
}
 