import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { UsuarioAdminLoginResponse } from '../../../shared/services/responses/usuario-admin/usuario-admin-login.response';
import {MatSelectModule} from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/services/API/usuario-admin/auth.service';
import { GoogleSigninButtonModule, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

@Component({
    selector: 'app-auth',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatSelectModule,
        GoogleSigninButtonModule
    ],
    templateUrl: './auth.component.html',
    styleUrl: './auth.component.css'
})
export class AuthComponent implements OnInit {
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private socialAuthService: SocialAuthService, 
  ){}

  public model: FormGroup;
  public isLoading:boolean = false;
  private ip: string = '192.168.0.2';
  public mostrarListaEmpresa:boolean = false;
  public usuarioAdminLoginResponse:UsuarioAdminLoginResponse;
  public socialUser!: SocialUser;
  public returnUrl = null;
 
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }
   
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required]],
      senha: ['', [Validators.required]],
      idEmpresa: [''], 
    });
    this.socialGoogle();
    setTimeout(() => { this.getIP(); }, 1000);
  }

  socialGoogle(){
    this.socialAuthService.authState.subscribe((user:SocialUser) => {
      this.socialUser = user;
      if(this.socialUser)
        this.singinWithGoogle(this.socialUser.idToken);
    });
    
  }

  singinWithGoogle(token: string) {
    if (this.isLoading){
      return;
    }
    this.isLoading = true;

    this.authService.signInWithGoogle(token, this.ip).subscribe({
      next:(response:UsuarioAdminLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
          
        this.authService.updateToken(response);

        this.isLoading = false;
        
        this.redirectUser();
      },
      error:(error:any) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  redirectUser(){
    if(this.returnUrl)
      this.router.navigate(['/' + this.returnUrl]);
    else
      this.router.navigate(['/home']);
  }
}
