import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { UpgradeModalComponent } from 'src/app/shared/components/upgrade-modal/upgrade-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ModuloEnum } from '../../../shared/enum/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enum/funcionalidade.enum';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { AuthService } from '../../../shared/services/API/usuario-admin/auth.service';
import { ModuloPerfilService } from '../../../shared/services/API/usuario-admin/modulo-perfil.service';
import { PerfilService } from '../../../shared/services/API/usuario-admin/perfil.service';
import { PerfilRequest } from '../../../shared/services/requests/usuario-admin/perfil.request';
import { ListaModuloPerfilResponse } from '../../../shared/services/responses/usuario-admin/lista-modulo-perfil.response';
import { ModuloPerfilStruct } from '../../../shared/services/structs/usuario-admin/modulo-perfil.struct';
import { PermissaoStruct } from '../../../shared/services/structs/usuario-admin/permissao.struct';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { PerfilResponse } from '../../../shared/services/responses/usuario-admin/perfil.response';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-perfil-cadastro',
  imports: [
      MenuComponent,
      FormsModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatIconModule,
      MatProgressSpinnerModule,
      CommonModule,
      MatTableModule,
      MatSelectModule,
      MatTooltipModule,
      MatSlideToggleModule,
      CdkAccordionModule,
      MatCheckboxModule,
  ],
  providers: [
    MatCheckbox,
    MatCheckboxChange,
  ],
  templateUrl: './perfil-cadastro.component.html',
  styleUrl: './perfil-cadastro.component.css'
})
export class PerfilCadastroComponent implements OnInit {

  expandedIndex: number = 0;
  moduloEnum: ModuloEnum = ModuloEnum.master;
  funcionalidadeEnum: FuncionalidadeEnum = FuncionalidadeEnum.master_perfil;
  model: FormGroup;
  _isLoading: boolean = false;
  isFirstLoading: boolean
  isUpdate: boolean;
  listaModuloPerfil: Array<ModuloPerfilStruct> = new Array<ModuloPerfilStruct>();
  idPerfil:number;
  isMaster: boolean;
  ip: string = '192.168.0.2';

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private perfilService: PerfilService,
    private moduloPerfilService: ModuloPerfilService,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.listModule();
  }

  ngOnInit(): void {
    this.isMaster = this.authService.getUserInfos().master;
    this.model = this.formBuilder.group({
      nome: ['', [Validators.required]],
      descricao: [''],
    });

    this.idPerfil == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idPerfil'))
      this.idPerfil = parseInt(this.activatedRoute.snapshot.paramMap.get('idPerfil')??'');
  }

  submit(): void {
    if (this._isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this._isLoading = true;
    const permissoesSelecionadas: Array<PermissaoStruct> = this.getSelectedRoles();
    const idsPermissoesSelecionadas: Array<number> = permissoesSelecionadas.map((permissao: PermissaoStruct) => permissao.idPermissao);
    const profile: PerfilRequest = {
      descricao: this.model.value.descricao,
      nome: this.model.value.nome,
      listaIdPermissao: idsPermissoesSelecionadas
    }
    let profileSubmitProfile;
    if (this.isUpdate) {
      profileSubmitProfile = this.perfilService.Alterar(this.idPerfil, profile);
    } else {
      profileSubmitProfile = this.perfilService.Incluir(profile);
    }

    profileSubmitProfile.subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this._isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', 'Perfil salvo com sucesso', AlertType.success);
        this._isLoading = false;
        this.router.navigate(['/master/perfil']);
      },
      error: (error: any) => {
        console.log(error);
        this._isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

    return;
  }

  getSelectedRoles(): Array<PermissaoStruct> {
    let permissoesSelecionadas: Array<PermissaoStruct> = new Array<PermissaoStruct>();
    this.listaModuloPerfil.forEach((modulo) => {
      modulo.listaFuncionalidade.forEach((funcionalidade) => {
        let selected = funcionalidade.listaPermissao.filter((permissao) => permissao.selecionado);
        permissoesSelecionadas = permissoesSelecionadas.concat(selected);
      });
    });

    return permissoesSelecionadas;
  }

  setProfileRoles(rolesList: Array<number>) {
    this.listaModuloPerfil.forEach((module: ModuloPerfilStruct) => {
      module.listaFuncionalidade.forEach((funcionalidade) => {
        funcionalidade.listaPermissao.forEach((permissao: PermissaoStruct) => {
          permissao.selecionado = rolesList.indexOf(permissao.idPermissao) > -1;
        });
      });
    });
  }

  populateProfileData() {
    this.perfilService.ObterPorId(this.idPerfil).subscribe({
      next: (response: PerfilResponse) => {
        this.setProfileRoles(response.listaIdPermissao);
        this.model.setValue({
          nome: response.nomePerfil,
          descricao: response.descricao,
        });

        this.isFirstLoading = false;

      },
      error: (error: any) => {
        console.log(error)
        this._isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listModule() {
    this.moduloPerfilService.get().subscribe({
      next: (response: ListaModuloPerfilResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this._isLoading = false;
          return;
        }

        this.listaModuloPerfil = response.listaModuloPerfil;

        if (this.idPerfil != null) {
          this.isFirstLoading = true;
          this.isUpdate = true;
          this.populateProfileData();
        }
      },
      error: (error: any) => {
        console.log(error);
        this._isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}