import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { AuthGuard } from './auth-guard.service';
import { HomeComponent } from './home/pages/home/home.component';
import { MasterHomeComponent } from './master/pages/master-home/master-home.component';
import { UsuarioAdminListaComponent } from './master/pages/usuario-admin-lista/usuario-admin-lista.component';
import { UsuarioAdminCadastroComponent } from './master/pages/usuario-admin-cadastro/usuario-admin-cadastro.component';
import { PerfilListaComponent } from './master/pages/perfil-lista/perfil-lista.component';
import { PerfilCadastroComponent } from './master/pages/perfil-cadastro/perfil-cadastro.component';

export const routes: Routes = [
    { path: '', component: AuthComponent, pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'master', component: MasterHomeComponent, canActivate: [AuthGuard] },
    { path: 'master/usuario', component: UsuarioAdminListaComponent, canActivate: [AuthGuard] },
    { path: 'master/usuario/cadastrar', component: UsuarioAdminCadastroComponent, canActivate: [AuthGuard] },
    { path: 'master/perfil', component: PerfilListaComponent, canActivate: [AuthGuard] },
    { path: 'master/perfil/cadastrar', component: PerfilCadastroComponent, canActivate: [AuthGuard] },
];
