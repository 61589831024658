import { Component } from '@angular/core';
import { ModuloEnum } from '../../../shared/enum/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enum/funcionalidade.enum';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../shared/component/confirm-modal/confirm-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Masks, MaskService } from '../../../shared/services/mask.service';
import { UsuarioAdminService } from '../../../shared/services/API/usuario-admin/usuario-admin.service';
import { UsuarioAdminResponse } from '../../../shared/services/responses/usuario-admin/usuario-admin.response';
import { UsuarioAdminRequest } from '../../../shared/services/requests/usuario-admin/usuario-admin.request';
import { UsuarioAdminLookupResponse } from '../../../shared/services/responses/usuario-admin/usuario-admin-lookup.response';

@Component({
    selector: 'app-usuario-admin-cadastro',
    imports: [
        MenuComponent,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CommonModule,
        MatTableModule,
        MatSelectModule,
        MatTooltipModule,
        MatSlideToggleModule,
        NgxMaskDirective
    ],
    providers: [
      provideNgxMask()
    ],
    templateUrl: './usuario-admin-cadastro.component.html',
    styleUrl: './usuario-admin-cadastro.component.css'
})
export class UsuarioAdminCadastroComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(private formBuilder: FormBuilder,
    private usuarioAdminService:UsuarioAdminService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private maskService:MaskService
  ){
    this.moduloEnum = ModuloEnum.master
    this.funcionalidadeEnum = FuncionalidadeEnum.master_usuario
  }

  public model: FormGroup;
  public _isLoading:boolean = false;
  public _usuarioLookupResponse:UsuarioAdminLookupResponse;
  public _usuarioResponse:UsuarioAdminResponse;
  public idUsuarioAdmin:number|null = null;
  public nomeEmpresaLogada:string;
  public masks: Masks;


  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    if (this.activatedRoute.snapshot.paramMap.get('idUsuarioAdmin'))
      this.idUsuarioAdmin = parseInt(this.activatedRoute.snapshot.paramMap.get('idUsuarioAdmin')??'');

    this.model = this.formBuilder.group({
      email: new FormControl({ value: '', disabled: false }).setValidators([Validators.required, Validators.email]),
      nomeUsuarioAdmin: new FormControl({ value: '', disabled: false }).setValidators([Validators.required]),
      telefone: new FormControl({ value: null, disabled: false }),
      ativo: new FormControl({ value: true, disabled: false }),
      cpf: new FormControl({ value: null, disabled: false }),
      listaIdPerfil: new FormControl({ value: '', disabled: false }),
      master: new FormControl({ value: false, disabled: false }),
    });

    this.popularLookup();

    if( this.idUsuarioAdmin != null){
      this.popularDadosUsuario();
    }
  }

  popularLookup(){
    this.usuarioAdminService.Lookup().subscribe({
      next: (usuarioLookupResponse:UsuarioAdminLookupResponse) => {
        if (usuarioLookupResponse.isError) {
          this.alertService.show("Erro", usuarioLookupResponse.errorDescription, AlertType.error);
          return;
        }

        this._usuarioLookupResponse = usuarioLookupResponse;
      },
      error: (error:any) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  popularDadosUsuario(){
    this.usuarioAdminService.ObterPorId(this.idUsuarioAdmin?? 0).subscribe({
      next: (usuarioResponse:UsuarioAdminResponse) => {
        if (usuarioResponse.isError) {
          this.alertService.show("Erro", usuarioResponse.errorDescription, AlertType.error);
          return;
        }
        this.model.get('nomeUsuarioAdmin')?.setValue(usuarioResponse.usuarioAdmin.nomeUsuarioAdmin);
        this.model.get('email')?.setValue(usuarioResponse.usuarioAdmin.email);
        this.model.get('telefone')?.setValue(usuarioResponse.usuarioAdmin.telefone);
        this.model.get('cpf')?.setValue(usuarioResponse.usuarioAdmin.cpf);
        this.model.get('listaIdPerfil')?.setValue(usuarioResponse.listaIdPerfil);
        this.model.get('master')?.setValue(usuarioResponse.usuarioAdmin.master);
        this.model.get('ativo')?.setValue(usuarioResponse.usuarioAdmin.ativo);

        this._usuarioResponse = usuarioResponse;

        this.model.get('email')?.disable();
        this.model.get('listaIdPerfil')?.enable();
        this.model.get('master')?.enable(); 
      },
      error: (error:any) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  submit(){
    if (this._isLoading || this.model.invalid) {
      return;
    }

    let usuarioRequest:UsuarioAdminRequest  = new UsuarioAdminRequest();

    usuarioRequest.idUsuarioAdmin = this.idUsuarioAdmin;
    usuarioRequest.email = this.model.get("email")?.value;
    usuarioRequest.nome = this.model.get("nomeUsuarioAdmin")?.value;
    usuarioRequest.telefone = this.model.get("telefone")?.value;
    usuarioRequest.cpf = this.model.get("cpf")?.value;
    usuarioRequest.master = this.model.get("master")?.value;
    usuarioRequest.ativo = this.model.get("ativo")?.value;
    usuarioRequest.listaIdPerfil = this.model.get("listaIdPerfil")?.value? this.model.get("listaIdPerfil")?.value: [];
    
    if(this.idUsuarioAdmin == null){
      this.novo(usuarioRequest);
    }
    else{
      this.alterar(usuarioRequest);
    }

  }

  private novo(usuarioRequest:UsuarioAdminRequest):void{
    this._isLoading = true;
    this.usuarioAdminService.Incluir(usuarioRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Usuário incluido com sucesso.", AlertType.success);
        this.router.navigate(['/master/usuario']);
      },
      error: (error:any) => {
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  private alterar(usuarioRequest:UsuarioAdminRequest):void{
    this._isLoading = true;
    this.usuarioAdminService.Alterar(this.idUsuarioAdmin??0, usuarioRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Usuário incluido com sucesso.", AlertType.success);
        this.router.navigate(['/master/usuario']);
      },
      error: (error:any) => {
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
}
