import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../services/API/usuario-admin/auth.service';
import { UsuarioAdminLoginResponse } from '../../../services/responses/usuario-admin/usuario-admin-login.response';

@Component({
    selector: 'app-action-user',
    imports: [MatButtonModule],
    templateUrl: './action-user.component.html',
    styleUrl: './action-user.component.css'
})
export class ActionUserComponent implements OnInit{
  @Input() showActionUser:boolean;
  @Output() showActionUserFalse =  new EventEmitter<number>()
  public userLoginResponse:UsuarioAdminLoginResponse;

  constructor(private authService: AuthService){

  }

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos()
  }

  clickContainerOut(){
    this.showActionUserFalse.emit();
  }

  clickLogout(){
    this.authService.logout();
  }
}
