import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { UsuarioAdminRequest } from '../../requests/usuario-admin/usuario-admin.request';
import { UsuarioAdminResponse } from '../../responses/usuario-admin/usuario-admin.response';
import { ListaUsuarioAdminResponse } from '../../responses/usuario-admin/lista-usuario-admin.response';
import { UsuarioAdminLookupResponse } from '../../responses/usuario-admin/usuario-admin-lookup.response';


@Injectable({
  providedIn: 'root'
})
export class UsuarioAdminService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(nomeUsuario:string, paginaAtual:number): Observable<ListaUsuarioAdminResponse> {
    let uri:string = 'UsuarioAdmin?nomeUsuario=' + nomeUsuario + '&paginaAtual=' + paginaAtual;
    
    return this.httpClient.get<ListaUsuarioAdminResponse>(environment.urlApiUsuarioAdmin + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public excluir(idUsuarioAdmin:number): Observable<ReturnStruct> {    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiUsuarioAdmin + `UsuarioAdmin/idUsuarioAdmin/${idUsuarioAdmin}`,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Incluir(usuarioRequest:UsuarioAdminRequest): Observable<ReturnStruct> {    
    return this.httpClient.post<ReturnStruct>(environment.urlApiUsuarioAdmin + `UsuarioAdmin`, usuarioRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Alterar(idUsuarioAdmin:number, usuarioRequest: UsuarioAdminRequest): Observable<ReturnStruct> {    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuarioAdmin + `UsuarioAdmin/idUsuarioAdmin/${idUsuarioAdmin}`, usuarioRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public ObterPorId(idUsuarioAdmin:number): Observable<UsuarioAdminResponse> {    
    return this.httpClient.get<UsuarioAdminResponse>(environment.urlApiUsuarioAdmin + `UsuarioAdmin/idUsuarioAdmin/${idUsuarioAdmin}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }


public Lookup(): Observable<UsuarioAdminLookupResponse> {    
    return this.httpClient.get<UsuarioAdminLookupResponse>(environment.urlApiUsuarioAdmin + `UsuarioAdmin/Lookup`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
 