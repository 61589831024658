<app-menu [moduloEnum]="moduloEnum" [funcionalidadeEnum]="funcionalidadeEnum"></app-menu>

<div class="container">
    <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
        <div class="row">
            <div class="col-12">
                <h1 class="title-default">Usuários</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" type="email">
                    <mat-icon class="material-icons-outlined" matSuffix matTooltip="O email será usado para realizar login e não pode ser alterado após a criação do usuário">info_outline</mat-icon>
                    @if (model.get('email')?.invalid) {
                        <mat-error>Informe o e-mail</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="nomeUsuarioAdmin" type="text">
                    @if (model.get('nomeUsuarioAdmin')?.invalid) {
                        <mat-error>Informe o nome</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
        <div class="row">
           
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput formControlName="telefone" type="text"  [mask]="masks.phone">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input matInput formControlName="cpf" type="text" [mask]="masks.cpf">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-slide-toggle formControlName="ativo">
                    Usuário {{model.get('ativo')?.value === true? 'Ativo' : 'Inativo'}}
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="col-12">
                    <h1 class="subtitle-default">Acessos</h1>
                </div>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Perfil</mat-label> 
                    <mat-select  formControlName="listaIdPerfil" multiple>
                        @if(_usuarioLookupResponse != undefined || _usuarioLookupResponse != null){
                            @for (perfil of _usuarioLookupResponse.listaPerfil; track perfil) {
                                <mat-option [value]="perfil.idPerfil">{{perfil.nome}}</mat-option>
                              }
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-slide-toggle formControlName="master">
                    Usuário master
                    <mat-icon class="material-icons-outlined" matSuffix matTooltip="Um usuário 'master' possui todas as permissões atribuídas a ele, independentemente do perfil que tenha.">info_outline</mat-icon>
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <button type="submit" mat-flat-button class="btn-primary button-big">
                    @if (_isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        Salvar
                    }
                </button>
            </div>
        </div>
    </form>
</div>