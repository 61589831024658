import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ListaModuloPerfilResponse } from '../../responses/usuario-admin/lista-modulo-perfil.response';

@Injectable({
  providedIn: 'root'
})
export class ModuloPerfilService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<ListaModuloPerfilResponse> {
    let uri:string = 'ModuloPerfil';
    
    return this.httpClient.get<ListaModuloPerfilResponse>(environment.urlApiUsuarioAdmin + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
 